.dnd {
  &-handle {
    display: flex;
    .handle {
      position: relative;
      font-size: 1.125rem;
      cursor: move;
      height: 1.25rem;
      width: 1.25rem;
      @if ($rtl==false) {
        margin-right: 0.5rem;
      }
      @if ($rtl==true) {
        margin-left: 0.5rem;
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-family: $nk-dashlite-font;
        content: $ni-move;
        font-size: 1rem;
      }
    }
  }
}
