@mixin bg-class($class, $color) {
  #{$class} {
    background-color: $color !important;
  }
  a#{$class},
  button#{$class} {
    &:hover,
    &:focus {
      background-color: darken($color, 10%) !important;
    }
  }
}

@each $name, $color in $extend-colors {
  .bg-#{$name} {
    background-color: $color !important;
  }
  .text-#{$name} {
    color: $color !important;
  }
}

@each $color, $value in $grays {
  .bg-gray-#{$color} {
    background-color: $value !important;
  }
}

$bg-abstract: linear-gradient(
  to right,
  #2c3782 calc(60% - 150px),
  #39469f calc(60% - 150px),
  #39469f 60%,
  #4856b5 60%,
  #4856b5 calc(60% + 150px),
  #5b6ac6 calc(60% + 150px),
  #5b6ac6 100%
);
.bg {
  &-abstract {
    background-image: $bg-abstract;
  }
}

.bg-white-1 {
  background: rgba($white, 0.1) !important;
}
.bg-white-2 {
  background: rgba($white, 0.2) !important;
}
.bg-white-3 {
  background: rgba($white, 0.3) !important;
}
.bg-white-4 {
  background: rgba($white, 0.4) !important;
}
.bg-white-5 {
  background: rgba($white, 0.5) !important;
}
.bg-white-6 {
  background: rgba($white, 0.6) !important;
}
.bg-white-7 {
  background: rgba($white, 0.7) !important;
}
.bg-white-8 {
  background: rgba($white, 0.8) !important;
}
.bg-white-9 {
  background: rgba($white, 0.9) !important;
}
