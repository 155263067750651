.timeline {
  color: $base-light;
  line-height: 1.3;
  + .timeline,
  &-list + .timeline-head {
    margin-top: 1.75rem;
  }
  &-head {
    font-size: $fx-sz-14;
    color: $base-light;
    margin-bottom: 1rem;
  }
  &-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
      padding-bottom: 1.5rem;
      &:before {
        position: absolute;
        height: calc(100% - 11px);
        width: 1px;
        background: $border-color;
        content: "";
        top: 13px;
        left: 5px;
      }
    }
  }
  &-status {
    position: relative;
    height: 11px;
    width: 11px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-top: 2px;
    &.is-outline {
      &:after {
        position: absolute;
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: $white;
        content: "";
        top: 2px;
        left: 2px;
      }
    }
  }
  &-date {
    position: relative;
    color: $base-light;
    width: 90px;
    @if ($rtl==false) {
      margin-left: 0.75rem;
    }
    @if ($rtl==true) {
      margin-right: 0.75rem;
    }
    flex-shrink: 0;
    line-height: 1rem;
    .icon {
      @if ($rtl==false) {
        right: 0;
        margin-right: 0.25rem;
      }
      @if ($rtl==true) {
        left: 0;
        margin-left: 0.25rem;
      }
      vertical-align: middle;
      color: $base-light;
      display: inline-block;
      position: absolute;
      top: 2px;
    }
  }
  &-data {
    @if ($rtl==false) {
      padding-left: 8px;
    }
    @if ($rtl==true) {
      padding-right: 8px;
    }
  }
  &-title {
    font-size: $fx-sz-15;
    color: $base-color;
    margin-bottom: 0.75rem;
  }
  &-des {
    color: $base-light;
    p {
      margin-bottom: 0.25rem;
    }
  }
  .time {
    display: block;
    font-size: $fx-sz-12;
    color: $base-light;
  }
}
@include media-breakpoint-up(sm) {
  .timeline + .timeline,
  .timeline-list + .timeline-head {
    margin-top: 2.5rem;
  }
}
@media (max-width: 413px) {
  .timeline {
    &-item {
      flex-wrap: wrap;
    }
    &-date {
      width: 80px;
    }
    &-data {
      @if ($rtl==false) {
        padding: 0.75rem 0 0 24px;
      }
      @if ($rtl==true) {
        padding: 0.75rem 24px 0 0;
      }
    }
  }
}
