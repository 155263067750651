// Animations
@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg) !important;
  }

  33% {
    transform: translateY(0) rotateZ(-2deg) !important;
  }

  66% {
    transform: translateY(0.3125em) rotateZ(2deg) !important;
  }

  100% {
    transform: translateY(0) rotateZ(0deg) !important;
  }
}

@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg) !important;
    opacity: 0 !important;
  }
}

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em !important;
    @if ($rtl==false) {
      left: 0.0625em !important;
    }
    @if ($rtl==true) {
      right: 0.0625em;
    }
    width: 0 !important;
  }

  54% {
    top: 0.125em !important;
    @if ($rtl==false) {
      left: 0.125em !important;
    }
    @if ($rtl==true) {
      right: 0.125em !important;
    }
    width: 0 !important;
  }

  70% {
    top: 0.625em;
    @if ($rtl==false) {
      left: -0.25em !important;
    }
    @if ($rtl==true) {
      right: -0.25em !important;
    }
    width: 1.625em !important;
  }

  84% {
    top: 1.0625em !important;
    @if ($rtl==false) {
      left: 0.75em !important;
    }
    @if ($rtl==true) {
      right: 0.75em !important;
    }
    width: 0.5em !important;
  }

  100% {
    top: 1.125em !important;
    @if ($rtl==false) {
      left: 0.1875em !important;
    }
    @if ($rtl==true) {
      right: 0.1875em;
    }
    width: 0.75em !important;
  }
}

@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em !important;
    @if ($rtl==false) {
      right: 1.375em !important;
    }
    @if ($rtl==true) {
      left: 1.375em;
    }
    width: 0;
  }

  65% {
    top: 1.25em !important;
    @if ($rtl==false) {
      right: 0.9375em !important;
    }
    @if ($rtl==true) {
      left: 0.9375em;
    }
    width: 0;
  }

  84% {
    top: 0.9375em !important;
    @if ($rtl==false) {
      right: 0;
    }
    @if ($rtl==true) {
      left: 0;
    }
    width: 1.125em !important;
  }

  100% {
    top: 0.9375em !important;
    @if ($rtl==false) {
      right: 0.1875em !important;
    }
    @if ($rtl==true) {
      left: 0.1875em;
    }
    width: 1.375em !important;
  }
}
