.btn {
  &-toolbar {
    &-sep {
      border-right: 1px solid $border-light;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      .card-tools & {
        padding: 0 !important;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }
  &-group {
    &.is-tags {
      .btn-xs {
        &:first-child {
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }
        &:last-child {
          margin-left: 0;
        }
        .icon {
          width: 1rem;
        }
      }
      .btn-dim:not(:disabled):not(.disabled):hover {
        border-color: transparent;
      }
    }
  }
}
