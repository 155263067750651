.rating {
  display: flex;
  align-items: center;
  margin: 0 -0.125rem;
  color: $warning;
  > * {
    padding: 0 0.125rem;
    display: inline-flex;
  }
  .icon {
    font-size: 1rem;
  }
  &-wrap {
    display: inline-flex;
    align-items: flex-start;
    .amount {
      line-height: 1.25rem;
    }
    .rating + span,
    span + .rating {
      margin-left: 0.75rem;
    }
  }
  &-pill {
    padding: 0.35rem 0.875rem;
    border-radius: 3rem;
  }
}

.rating {
  &-card {
    &-description {
      .icon {
        color: $primary;
      }
      li {
        color: $base-400;
      }
    }
  }
  &-progress {
    display: flex;
    align-items: center;
  }
}
.icon + span,
span + .icon {
  margin-left: 0.25rem;
}
