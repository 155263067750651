$fixed-widths: 80px, 90px, 100px, 110px, 120px, 125px, 130px, 140px, 150px,
  175px, 200px, 220px, 225px, 250px, 300px, 350px, 400px, 550px;

@each $size in $fixed-widths {
  .w-#{$size} {
    width: $size !important;
  }
  .w-min-#{$size} {
    min-width: $size !important;
  }
  .w-max-#{$size} {
    max-width: $size !important;
  }
}
.w-max-100 {
  max-width: 100% !important;
}
.w-min-100 {
  min-width: 100% !important;
}
.w-max-auto {
  max-width: inherit !important;
}
.w-min-auto {
  min-width: auto !important;
}

$pc-widths: 15 15%, 20 20%, 30 30%, 35 35%, 40 40%, 45 45%, 55 55%, 60 60%,
  65 65%, 70 70%, 80 80%, 85 85%, 90 90%, 95 95%;
@each $name, $size in $pc-widths {
  .w-#{$name} {
    width: $size !important;
  }
}

// width by name
$wide-sizes: xs 520px 576px, sm 720px 768px, md 960px 992px, lg 1140px 1200px,
  xl 1240px 1280px;
//$name   $wide     $point
@each $name, $wide, $point in $wide-sizes {
  @media (min-width:#{$point}) {
    .wide-#{$name} {
      max-width: $wide !important;
      &-fix {
        width: $wide !important;
      }
    }
  }
}
@each $name, $wide in $wide-sizes {
  .wide-#{$name} {
    &-fix {
      width: $wide !important;
    }
  }
}

.wide-xs-fix,
.wide-sm-fix,
.wide-md-fix,
.wide-lg-fix {
  @extend .w-max-100;
}
@media (min-width: 1200px) and (max-width: 1660px) {
  .wide-fit {
    max-width: 1140px;
  }
}

$fixed-heights: 100px, 150px, 175px, 200px, 225px, 250px, 275px, 300px, 325px,
  350px, 375px, 400px, 425px, 450px, 475px, 500px;

@each $size in $fixed-heights {
  .h-#{$size} {
    height: $size !important;
  }
  .h-min-#{$size} {
    min-height: $size !important;
  }
  .h-max-#{$size} {
    max-height: $size !important;
  }
}
