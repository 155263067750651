.breadcrumb {
  &-item {
    font-size: $breadcrumb-font-size;
    font-weight: $breadcrumb-font-weight;
    text-transform: $breadcrumb-font-case;
    letter-spacing: $breadcrumb-letter-spacing;
    a:not(:hover) {
      color: $breadcrumb-not-hover-color;
    }
    + .breadcrumb-item::before {
      .breadcrumb-pipe & {
        content: "|";
      }
    }
    + .breadcrumb-item::before {
      .breadcrumb-arrow & {
        font-family: $nk-dashlite-font;
        content: $ni-forward-ios;
      }
    }
    &.active {
      &:not(:only-child) {
        font-weight: $fw-normal;
      }
    }
  }
}

.breadcrumb-alt {
  .breadcrumb {
    &-item {
      text-transform: unset;
      letter-spacing: 0;
      color: $base-light;
    }
  }
}
.breadcrumb-xs {
  .breadcrumb {
    &-item {
      + .breadcrumb-item {
        padding-left: 0.25rem;
        &:before {
          padding-right: 0.25rem;
          font-size: 0.8em;
        }
      }
    }
  }
}
.breadcrumb-sm {
  .breadcrumb {
    &-item {
      font-size: $fx-sz-13;
    }
  }
}
