.table {
  thead tr:last-child {
    th {
      border-bottom: $table-border-width solid $table-border-color;
    }
  }
  &-dark thead tr:last-child th,
  thead.thead-dark tr:last-child th {
    border-color: $table-dark-border-color;
  }
  td,
  th {
    &:first-child {
      padding-left: $table-cell-padding-x-sm;
    }
    &:last-child {
      padding-right: $table-cell-padding-x-sm;
    }
  }

  th {
    line-height: 1.1;
    &.overline-title {
      line-height: 1.25rem;
      font-weight: $fw-medium;
    }
  }
  caption {
    font-style: italic;
    padding-left: $table-cell-padding-x-sm;
  }
  &-bordered {
    td,
    th {
      padding-left: $table-cell-padding-bordered;
      padding-right: $table-cell-padding-bordered;
    }
  }
  &-plain {
    td,
    th {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  &-lg {
    td {
      padding-top: ($table-cell-padding * 2);
      padding-bottom: ($table-cell-padding * 2);
    }
    th {
      padding-top: ($table-cell-padding * 1.5);
      padding-bottom: ($table-cell-padding * 1.5);
    }
  }
  &-vm td,
  &-vm th,
  td.vm,
  th.vm {
    vertical-align: middle;
  }
}
@include media-breakpoint-up(sm) {
  .table {
    &-lg td,
    &-lg th {
      &:first-child {
        padding-left: ($table-cell-padding-sm + $table-cell-padding-x-sm);
      }
      &:last-child {
        padding-right: ($table-cell-padding-sm + $table-cell-padding-x-sm);
      }
    }
  }
}
