// Variables
$msg-item-gap-x: 1.25rem;
$msg-item-gap-x-tab: 2rem;
$msg-item-gap-x-dsk: 1.75rem;

$msg-item-gap-y: 1.25rem;
$msg-body-gap-x: 1.25rem;
$msg-body-gap-x-tab: 2.25rem;
$msg-body-gap-x-dsk: 2.5rem;

.nk-msg {
  &-nav {
    position: relative;
    border-bottom: 1px solid $border-light;
    padding: 0 $msg-item-gap-x;
    background: $lighter;
    z-index: 2;
    .search-wrap {
      padding: 0 1rem;
      bottom: -1px;
      border-bottom: 1px solid $border-light;
    }
    .search-submit {
      transform: translate(0.25rem, -50%);
    }
  }
  &-menu {
    display: flex;
    margin: 0 -0.75rem;
    &-item {
      padding: 0 0.75rem;
      a {
        display: inline-flex;
        position: relative;
        color: $base-light;
        font-weight: $fw-medium;
        font-size: 0.875rem;
        line-height: 1.5rem;
        padding: 1rem 0;
        &:after {
          position: absolute;
          content: "";
          height: 2px;
          background: $accent-color;
          bottom: -1px;
          left: 0;
          right: 0;
          opacity: 0;
          transition: 0.3s ease;
        }
        .icon {
          font-size: 1.125rem;
          line-height: 1.5rem;
        }
        &:hover {
          color: $accent-color;
        }
      }
      &.active {
        a {
          color: $accent-color;
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.nk-msg {
  position: relative;
  display: flex;
  overflow: hidden;
  min-height: calc(100vh - (#{$header-height} + 64px));
  max-height: calc(100vh - (#{$header-height} + 64px));
  background: $white;
  &-aside {
    background: $lighter;
    width: 100%;
    overflow: hidden;
    max-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .nk-msg-boxed & {
      background: rgba($lighter, 0.7);
      border-top-left-radius: ($border-radius - 1);
      border-bottom-left-radius: ($border-radius - 1);
    }
  }

  &-list {
    // max-height: calc(100% - 57px);
    max-height: 100%;
    height: 100%;
    overflow: auto;
  }
  &-item {
    display: flex;
    padding: $msg-item-gap-y $msg-item-gap-x;
    &:not(:last-child) {
      border-bottom: 1px solid $border-light;
    }
    cursor: pointer;
    &.active,
    &.current {
      background: $white;
      cursor: default;
    }
  }
  &-info {
    max-width: calc(100% - 52px);
    margin-left: 0.75rem;
    flex-grow: 1;
  }
  &-from {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-context {
    display: flex;
    justify-content: space-between;
    margin-top: 0.25rem;
    .asterisk {
      margin-top: auto;
      // moved to asterisk.scss
    }
  }
  &-sender {
    display: inline-flex;
    align-items: center;
    color: $base-light;
    font-size: $fx-sz-12;
    .name {
      margin-right: 0.5rem;
      .is-unread & {
        font-weight: $fw-medium;
        color: $base-color;
      }
    }
  }
  &-meta {
    display: inline-flex;
    align-items: center;
    color: $base-light;
    font-size: $fx-sz-12;
    .attchment {
      .icon {
        font-size: 1.125rem;
      }
    }
    .date {
      margin-left: 0.75rem;
    }
  }
  &-text {
    max-width: calc(100% - 35px);
    flex-grow: 0;
    padding-right: 0.5rem;
    .title {
      font-size: $fx-sz-15;
      margin-bottom: 0.25rem;
      @extend .ellipsis;
    }
    p {
      font-size: $fx-sz-13;
      color: $base-light;
      max-height: 42px;
      overflow: hidden;
      .is-unread & {
        color: $base-text;
        font-weight: $fw-medium;
      }
    }
  }
  &-lables {
    text-align: center;
    margin: -0.125rem;
    display: inline-flex;
    flex-direction: column;
    > div {
      padding: 0.125rem;
    }
    .icon {
      font-size: 1.125rem;
    }
    .badge {
      line-height: 14px;
      font-size: 10px;
      padding: 0 0.25rem;
    }
  }
  &-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: padding 0.3s ease-in-out;
    opacity: 0;
    pointer-events: none;
    z-index: 5;
    &.show-message {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &-profile {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    width: 280px;
    height: 100%;
    max-height: 100%;
    transition: transform 0.3s ease-in-out;
    background: $white;
    z-index: 100;
    &-toggle {
      position: absolute;
      height: 36px;
      width: 36px;
      display: none;
      align-items: center;
      justify-content: center;
      bottom: -18px;
      right: -18px;
      margin-right: 40px;
      transition: right 0.3s ease-in-out, margin 0.3s ease-in-out 0.2s;
      border: 1px solid $border-light;
      background-color: $white;
      border-radius: 50%;
      font-size: 1.125rem;
      z-index: 2;
      color: $base-light;
      z-index: 110;
      &.active {
        margin-right: 0;
        transform: rotate(-180deg);
      }
    }
    &-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $sidebar-overlay;
      z-index: 600;
      z-index: 90;
      animation: overlay-fade-in 0.4s ease 1;
    }
    &.visible {
      transform: none;
    }
  }
  &-head {
    position: relative;
    padding: 0.75rem $msg-body-gap-x;
    border-bottom: 1px solid $border-light;
    .title {
      margin-bottom: 0.5rem;
    }
    &-meta {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-hide {
    font-size: 1rem;
  }
  &-tags {
    margin: 0 -0.75rem;
    li {
      padding: 0 0.75rem;
      color: $base-light;
      > span {
        display: inline-flex;
        align-items: center;
        .icon {
          color: $accent-color;
          margin-right: 0.25rem;
        }
      }
    }
  }

  &-actions {
    display: flex;
    margin: 0 -0.25rem;
    li {
      padding: 0 0.25rem;
    }
  }
}

@include media-breakpoint-down(mb) {
  .nk-msg {
    &-boxed {
      border: none;
      border-radius: $border-radius;
      min-height: calc(100vh - (#{$header-height}));
      max-height: calc(100vh - (#{$header-height}));
      // margin: -24px -18px; // @io-v2.0
    }
  }
}
@include media-breakpoint-up(sm) {
  .nk-msg {
    &-nav {
      padding: 0 $msg-item-gap-x-tab;
      .search-wrap {
        padding: 0 1.7rem;
      }
    }
    &-item {
      padding: $msg-item-gap-y $msg-item-gap-x-tab;
    }
    &-head {
      padding: 1rem $msg-body-gap-x-tab;
      .title {
        margin-bottom: 1rem;
      }
    }
  }
}
@include media-breakpoint-between(sm, md) {
  .nk-msg {
    &-text {
      .title {
        margin-top: 0.125rem;
        margin-bottom: 0.375rem;
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .nk-msg {
    &-nav {
      padding: 0 $msg-item-gap-x-dsk;
      .search-wrap {
        padding: 0 ($msg-item-gap-x-dsk - 0.325rem);
      }
    }
    &-item {
      padding: $msg-item-gap-y $msg-item-gap-x-dsk;
    }
    &-head {
      padding: 2rem $msg-body-gap-x-dsk;
      .title {
        max-height: 82px;
        overflow: hidden;
      }
    }
    &-aside {
      width: 320px;
      border-right: 1px solid $border-light;
    }
    &-body {
      position: static;
      opacity: 1;
      pointer-events: auto;
    }
    &-profile {
      border-left: 1px solid $border-light;
      &-toggle {
        margin-right: 55px;
        display: flex;
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
  .nk-msg {
    &-aside {
      width: 380px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .profile-shown {
    .nk-msg-profile-toggle {
      right: 262px;
      .has-apps-sidebar & {
        right: -18px;
      }
    }
  }
}
@media (max-width: 1279px) {
  .has-apps-sidebar {
    .profile-shown {
      .nk-msg-profile-toggle {
        right: 262px;
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
  .nk-msg-body {
    &.profile-shown {
      padding-right: 280px;
    }
  }
}
@media (min-width: 1280px) {
  .has-apps-sidebar {
    .nk-msg-body {
      &.profile-shown {
        padding-right: 280px;
      }
    }
  }
}
