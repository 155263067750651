/// Nav Switch
/////////////////////////////
.nav-switch {
  display: inline-flex;
  margin-bottom: 1.5rem;
  border: 1px solid $border-color;
  border-radius: 30px;
  padding: 5px;
  .nav {
    &-link {
      padding: 5px 16px;
      line-height: 20px;
      border-radius: 20px;
      position: static;
      text-transform: uppercase;
      font-family: $base-font-family;
      font-weight: $fw-bold;
      font-size: 12px;
      color: $base-light;
      &.active {
        background: $primary;
        color: #fff;
      }
      &:after {
        display: none;
      }
    }
    &-item {
      padding: 0;
      margin: 0 1px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .nav-switch {
    margin-bottom: 2.5rem;
  }
}

/// Nav Switch
/////////////////////////////
.nav-switch-s2 {
  display: inline-flex;
  border-radius: $border-radius;
  border: none;
  .nav {
    &-link {
      padding: 0.25rem 0.7rem;
      line-height: 20px;
      border-radius: 0;
      position: static;
      text-transform: uppercase;
      font-family: $base-font-family;
      font-weight: $fw-bold;
      font-size: 12px;
      color: $base-light;
      border: 1px solid $border-color;
      &:hover,
      &:focus {
        color: $base-text;
        background: $lighter;
        border: 1px solid $border-color;
      }

      &.active {
        color: $base-color;
        border: 1px solid $border-color;
        background: $lighter;
        box-shadow: inset 0 0 4px -1px rgba($base-light, 0.25);
      }
      &:after {
        display: none;
      }
    }
    &-item {
      padding: 0;
      margin: 0;
      &:last-child {
        margin-right: 0;
        .nav-link {
          border-radius: 0 $border-radius $border-radius 0;
        }
      }
      &:first-child {
        margin-left: 0;
        .nav-link {
          border-radius: $border-radius 0 0 $border-radius;
        }
      }
      &:not(:first-child) {
        margin-left: -1px;
      }
    }
  }
}
